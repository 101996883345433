import { ClientDetailDto } from '@shared/dto/clients.dto'
import { ClientFormData } from '@app/components/organisms/ClientForm/ClientForm'

import * as DTOs from '@shared/dto'

export const transformClientToFormData = (
  client: ClientDetailDto | null,
): Partial<ClientFormData> => {
  return {
    companyName: client?.company_name ?? '',
    street: client?.street ?? '',
    city: client?.city ?? '',
    zipCode: client?.zip_code ?? '',
    country: client?.country ?? null,
  }
}

export const transformFormDataToPartialClientDetailDto = (
  formValues: ClientFormData,
): Omit<DTOs.CreateClientDto, 'cancellation_fee_text'> => {
  return {
    company_name: formValues.companyName,
    street: formValues.street,
    city: formValues.city,
    zip_code: formValues.zipCode,
    country_id: formValues.country?.id,
  }
}
