import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '@app/components/atoms/Typography/Typography'
import Dialog from '@app/components/atoms/Dialog/Dialog'
import ContactPersonForm from '@app/components/organisms/ContactPersonForm/ContactPersonForm'

import { CreateContactPersonDto } from '@shared/dto'

interface CreateContactPersonDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: Omit<CreateContactPersonDto, 'client_id'>) => void
  isLoading?: boolean
}

const CreateContactPersonDialog = ({
  onSubmit,
  isOpen,
  onClose,
  isLoading,
}: CreateContactPersonDialogProps) => {
  const { t } = useTranslation()

  return (
    <StyledDialog
      classes={{ paper: 'Dialog__paper' }}
      open={isOpen}
      onClose={onClose}
    >
      <Typography variant="dialogHeading">
        {t('organisms.CreateContactPersonDialog.newContactPerson')}
      </Typography>
      <StyledContactPersonForm isLoading={isLoading} onSubmit={onSubmit} />
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  .Dialog__paper {
    padding: 2rem;
    min-width: 40rem;
    min-height: 20rem;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
`

const StyledContactPersonForm = styled(ContactPersonForm)`
  margin-top: 2rem;
`

export default CreateContactPersonDialog
