import format from 'number-format.js'
import { LegTypes } from '@shared/enums'

import { DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY } from '@shared/constants'
import { LegDetailDto } from '@shared/dto'

// used fe
export function getFormattedPrice(price: number, currency: string): string {
  return format(`${DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY} ${currency}`, price)
}

// used fe
export function getCancelledLegs<
  T extends Pick<LegDetailDto, 'type' | 'remove_leg_id' | 'remove_leg'>,
>(legs: T[], cancellationOfferId?: number, rebookingOfferId?: number): T[] {
  return legs.filter((leg) => {
    const isRemovedLeg = leg.type === LegTypes.Removed

    const removeLegIdExists = Boolean(leg.remove_leg_id)

    const isCancellationLeg =
      leg.remove_leg && leg.remove_leg.offer_id === cancellationOfferId

    const isRebookingLeg =
      leg.remove_leg && leg.remove_leg.offer_id === rebookingOfferId

    return (
      isRemovedLeg && removeLegIdExists && (isCancellationLeg || isRebookingLeg)
    )
  })
}

// used fe
export function getOptimizedLegs<
  T extends Pick<LegDetailDto, 'type' | 'remove_leg' | 'remove_leg_id'>,
>(legs: T[], cancellationOfferId?: number, rebookingOfferId?: number): T[] {
  return legs.filter((leg) => {
    const isRemovedLeg = leg.type === LegTypes.Removed

    const removeLegIdExists = Boolean(leg.remove_leg_id)

    const isCancellationLeg =
      leg.remove_leg && leg.remove_leg.offer_id === cancellationOfferId

    const isRebookingLeg =
      leg.remove_leg && leg.remove_leg.offer_id === rebookingOfferId

    return (
      isRemovedLeg && removeLegIdExists && !isCancellationLeg && !isRebookingLeg
    )
  })
}

//used fe
export function getLegsRemovedFromOffer<
  T extends Pick<LegDetailDto, 'type' | 'remove_leg_id'> &
    Partial<Pick<LegDetailDto, 'offer_id'>>,
>(legs: T[], offerId: number): T[] {
  return legs.filter((leg) => {
    const isRemovedLeg = leg.type === LegTypes.Removed
    const isLegFromInputOfferId = leg.offer_id === offerId
    const removeLegIdExists = Boolean(leg.remove_leg_id)

    return isRemovedLeg && isLegFromInputOfferId && !removeLegIdExists
  })
}
