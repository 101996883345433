import { getLegTurnaroundTimeInMinutes } from '@shared/utils/legUtils'
import * as DTOs from '@shared/dto'

const getMarketplaceExtensionDepartureDateBoundaryBasedOnPrecedingItem = (
  precedingScheduleItem: Pick<
    DTOs.ScheduleDetailDto,
    'arrival_date' | 'marketplace_arrival_date'
  >,
  aircraft: Pick<
    DTOs.AircraftDetailDto,
    | 'turnaround_before_empty_leg_in_minutes'
    | 'turnaround_before_occupied_leg_in_minutes'
  >,
) => {
  if (!precedingScheduleItem) {
    return
  }

  const resDate = new Date(
    precedingScheduleItem.marketplace_arrival_date &&
    precedingScheduleItem.marketplace_arrival_date >
      precedingScheduleItem.arrival_date
      ? precedingScheduleItem.marketplace_arrival_date
      : precedingScheduleItem.arrival_date,
  )

  const toReturn = new Date(resDate.getTime())
  toReturn.setMinutes(
    toReturn.getMinutes() + aircraft.turnaround_before_empty_leg_in_minutes,
  )

  return toReturn
}

const getMarketplaceExtensionArrivalDateBoundaryBasedOnSucceedingItem = (
  succeedingScheduleItem: Pick<
    DTOs.ScheduleDetailDto,
    'type' | 'departure_date' | 'marketplace_departure_date'
  >,
  aircraft: Pick<
    DTOs.AircraftDetailDto,
    | 'turnaround_before_empty_leg_in_minutes'
    | 'turnaround_before_occupied_leg_in_minutes'
  >,
) => {
  if (!succeedingScheduleItem) {
    return
  }

  const turnaroundInMinutes = getLegTurnaroundTimeInMinutes(
    succeedingScheduleItem.type,
    aircraft,
  )

  const resDate = new Date(
    succeedingScheduleItem.marketplace_departure_date &&
    succeedingScheduleItem.marketplace_departure_date <
      succeedingScheduleItem.departure_date
      ? succeedingScheduleItem.marketplace_departure_date
      : succeedingScheduleItem.departure_date,
  )

  const toReturn = new Date(resDate.getTime())
  toReturn.setMinutes(toReturn.getMinutes() - turnaroundInMinutes)

  return toReturn
}

export const getMarketplaceExtensionDepartureDateBoundary = (
  scheduleItem: Pick<DTOs.ScheduleDetailDto, 'departure_date'>,
  precedingScheduleItem: Pick<
    DTOs.ScheduleDetailDto,
    'arrival_date' | 'marketplace_arrival_date'
  >,
  aircraft: Pick<
    DTOs.AircraftDetailDto,
    | 'marketingLegDurationMinutes'
    | 'turnaround_before_empty_leg_in_minutes'
    | 'turnaround_before_occupied_leg_in_minutes'
  >,
) => {
  const minBoundary = new Date(new Date(scheduleItem.departure_date).getTime())
  minBoundary.setMinutes(
    minBoundary.getMinutes() - aircraft.marketingLegDurationMinutes,
  )

  const boundary =
    getMarketplaceExtensionDepartureDateBoundaryBasedOnPrecedingItem(
      precedingScheduleItem,
      aircraft,
    )

  if (!boundary || boundary < minBoundary) {
    return minBoundary
  }

  return boundary
}

export const getMarketplaceExtensionArrivalDateBoundary = (
  scheduleItem: Pick<DTOs.ScheduleDetailDto, 'arrival_date'>,
  succeedingScheduleItem: Pick<
    DTOs.ScheduleDetailDto,
    'type' | 'departure_date' | 'marketplace_departure_date'
  >,
  aircraft: Pick<
    DTOs.AircraftDetailDto,
    | 'marketingLegDurationMinutes'
    | 'turnaround_before_empty_leg_in_minutes'
    | 'turnaround_before_occupied_leg_in_minutes'
  >,
) => {
  const maxBoundary = new Date(new Date(scheduleItem.arrival_date).getTime())
  maxBoundary.setMinutes(
    maxBoundary.getMinutes() + aircraft.marketingLegDurationMinutes,
  )

  const boundary =
    getMarketplaceExtensionArrivalDateBoundaryBasedOnSucceedingItem(
      succeedingScheduleItem,
      aircraft,
    )

  if (!boundary || boundary > maxBoundary) {
    return maxBoundary
  }

  return boundary
}
