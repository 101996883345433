import { PartialRequestDto } from '@shared/dto'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'
import Box from '@material-ui/core/Box'
import { StyledTooltip } from '@app/components/molecules/RequestsGridView/Cells'

import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'

export const WarningCell = ({
  cell,
}: CellProps<PartialRequestDto, unknown>) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" pl={1} pt={1}>
      <StyledTooltip
        title={t('pages.Request.cabotage.tooltip') as string}
        $isActive={cell.row.original.hasCabotage}
      >
        <ErrorIcon color="error" />
      </StyledTooltip>
    </Box>
  )
}
