import React, { useState, RefCallback, ForwardedRef, forwardRef } from 'react'
import styled from 'styled-components'
import MaskedInput, { MaskedInputProps, maskArray } from 'react-text-mask'

import FormControl from '@material-ui/core/FormControl'
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'

import InputBase, {
  InputBaseProps,
  InputBaseComponentProps,
} from '@material-ui/core/InputBase'

import PriceFormatter from '@app/components/atoms/TextField/PriceFormatter'

export type TextFieldSizes = 'small' | 'normal' | 'large'

export type TextFieldVariants = 'normal' | 'secondary'

export interface TextFieldProps extends InputBaseProps {
  size?: TextFieldSizes
  id?: string
  label?: string
  mask?: maskArray | ((value: string) => maskArray)
  variant?: TextFieldVariants
  warning?: boolean
}

interface TextMaskCustomProps extends MaskedInputProps {
  inputRef: RefCallback<HTMLElement>
}

interface StyledInputBaseProps extends InputBaseProps {
  $size: TextFieldSizes
  $scale: number
  $variant: TextFieldProps['variant']
  $warning?: boolean
  label?: string
}

interface StyledInputLabelProps extends InputLabelProps {
  $variant: TextFieldProps['variant']
}

export const TEXT_FIELD_SIZES_TO_SCALE_MAP = {
  small: 1.75,
  normal: 2.375,
  large: 3,
}

export const TextFieldPriceFormatter = PriceFormatter

export const TextField = forwardRef(
  (
    {
      label,
      id,
      mask,
      type,
      endAdornment,
      className,
      error,
      warning,
      fullWidth,
      classes,
      size = 'normal',
      variant = 'normal',
      ...props
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [isPasswordShown, setIsPasswordShown] = useState(false)

    return (
      <FormControl
        variant="standard"
        className={className}
        error={error}
        fullWidth={fullWidth}
        ref={ref}
      >
        <TextFieldInputLabel
          $variant={variant}
          shrink
          htmlFor={id}
          classes={{
            focused: 'TextFieldLabel__focused',
          }}
        >
          {label}
        </TextFieldInputLabel>
        <TextFieldInputBase
          label={label}
          $size={size}
          $scale={TEXT_FIELD_SIZES_TO_SCALE_MAP[size]}
          $variant={variant}
          id={id}
          error={error}
          $warning={warning}
          fullWidth={fullWidth}
          inputProps={{
            // @see https://stackoverflow.com/a/44984917/3210641
            'data-lpignore': true,
            mask,
            ...props.inputProps,
          }}
          type={isPasswordShown ? 'text' : type}
          inputComponent={
            mask
              ? (TextMaskCustom as React.ElementType<InputBaseComponentProps>)
              : props.inputComponent
          }
          endAdornment={
            endAdornment ??
            (type === 'password' ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                >
                  {isPasswordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ) : null)
          }
          {...props}
          classes={{
            disabled: 'TextField__disabled',
            focused: 'TextField__focused',
            ...classes,
          }}
        />
      </FormControl>
    )
  },
)

TextField.displayName = 'TextField'

const TextMaskCustom = ({
  inputRef,
  ...props
}: TextMaskCustomProps): JSX.Element => {
  return (
    <MaskedInput
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      {...props}
      placeholderChar={props.placeholderChar ?? '\u2000'}
      guide={props.guide ?? false}
    />
  )
}

export const TextFieldInputLabel = styled(InputLabel)<StyledInputLabelProps>`
  font-size: 0.75rem;
  position: relative;
  transform: none;

  ${({ $variant, theme }) => {
    switch ($variant) {
      case 'normal':
        return `
          color: ${theme.palette.grey[800]};
        `

      case 'secondary':
        return `
          color: ${theme.text.heading.regular};

          &.TextFieldLabel__focused {
            color: ${theme.text.heading.regular};
          }
        `
    }
  }}
`

export const TextFieldInputBase = styled(InputBase)<StyledInputBaseProps>`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 0.1875rem;
  padding: 0.5rem;
  margin-top: ${({ label }) => (label ? '0.5rem' : '0')};
  transition: border-color 0.5s;
  height: ${({ multiline, $scale }) => !multiline && `${$scale}rem`};

  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }

  border: 1px solid
    ${({ error, $warning, theme }) => {
      if (error) {
        return theme.palette.error.main
      }

      if ($warning) {
        return theme.palette.warning.main
      }

      return theme.palette.grey[600]
    }};

  ${({ $size, multiline }) => {
    switch ($size) {
      case 'large':
        return `
          height: ${!multiline && '3.125rem'};
          font-size: 1rem;`

      case 'small':
        return `
          height: ${!multiline && '1.5rem'};
          font-size: 0.5rem;`

      default:
        return `
          height: ${!multiline && '2.375rem'};
          font-size: 0.875rem;`
    }
  }};

  &.TextField__disabled {
    background: ${({ theme }) => theme.palette.grey[100]};
  }

  &.TextField__focused {
    border-color: ${({ $variant, theme }) => {
      switch ($variant) {
        case 'normal':
          return theme.palette.primary.main

        case 'secondary':
          return theme.text.heading.regular
      }
    }};
  }
`

export default TextField
