import React, {
  ChangeEvent,
  FocusEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { Value } from '@material-ui/lab/useAutocomplete'
import { useDebounce } from '@app/hooks/useDebounce'

import Popper from '@material-ui/core/Popper'
import { AutocompleteChangeReason } from '@material-ui/lab'

import Select, { SelectProps } from '@app/components/atoms/Select/Select'
import { PaginatedList } from '@shared/dto'

export type EntityPickerValue<
  Entity,
  Multiple extends boolean | undefined,
> = Value<Entity, Multiple, false, true>

export interface EntityPickerProps<
  Entity,
  Multiple extends boolean | undefined = false,
> extends Omit<
    SelectProps<Entity, Multiple, false, true>,
    | 'onInputChange'
    | 'options'
    | 'variant'
    | 'loading'
    | 'popupIcon'
    | 'onBlur'
    | 'value'
  > {
  value: EntityPickerValue<Entity, Multiple>
  searchKey: keyof Entity
  fallbackKey: keyof Entity
  availableItems?: Entity[]
  getNotFoundMessage?: (search: string) => string
  getErrorMessage?: (error: unknown) => string
  popupIcon?: ReactNode
  onTriggerSearch: (
    searchKeyword: string,
  ) => Promise<AxiosResponse<PaginatedList<Entity>>>
  onBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
  >
  onTransformSearch?: (search: string) => string
}

const REQUEST_DEBOUNCE_TIME = 500

const EntityPicker = <Entity extends object, Multiple extends boolean = false>({
  availableItems,
  onChange,
  searchKey,
  fallbackKey,
  onTriggerSearch,
  popupIcon,
  getNotFoundMessage,
  getErrorMessage,
  noOptionsText,
  placeholder,
  classes,
  onTransformSearch,
  ...props
}: EntityPickerProps<Entity, Multiple>): JSX.Element => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const debounce = useDebounce()

  const inputRef = useRef<Element | null>(null)

  const isValueOfEntityType = (
    value:
      | string
      | Entity
      | NonNullable<Entity>
      | (string | Entity)[]
      | null
      | undefined,
  ): value is Entity =>
    Boolean(typeof value === 'object' && value && !Array.isArray(value))

  const initialSearch = isValueOfEntityType(props.value)
    ? String(props.value[searchKey])
    : ''

  const [search, setSearch] = useState(initialSearch)
  const [isOnBlurRequestLoading, setIsOnBlurRequestLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState<Entity | undefined>()

  const [options, setOptions] = useState<Entity[]>(availableItems ?? [])
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)

  const loadOptions = (search: string) => {
    return debounce(async () => {
      setIsLoadingOptions(true)

      try {
        const { data } = await onTriggerSearch(search)

        const isInputFocused = document.activeElement === inputRef.current

        if (isInputFocused) {
          setOptions(data.data)
        }
      } catch (error) {
        const errorMessage =
          getErrorMessage?.(error) ??
          t('atoms.EntityPicker.defaultErrorMessage')

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      } finally {
        setIsLoadingOptions(false)
      }
    }, REQUEST_DEBOUNCE_TIME)
  }

  const onInputTextChange = (event: ChangeEvent<object>) => {
    const getIsTargetInput = (
      event: ChangeEvent<object>,
    ): event is ChangeEvent<HTMLInputElement> =>
      !!(event as ChangeEvent<HTMLInputElement>)?.target

    if (getIsTargetInput(event)) {
      const nextSearch = event.target.value

      setSearch(nextSearch)

      loadOptions(nextSearch)

      setSelectedValue(undefined)
    }
  }

  const handleChange = (
    event: ChangeEvent<object>,
    value: Value<Entity, Multiple, false, true>,
    reason: AutocompleteChangeReason,
  ) => {
    setOptions([])

    if (isValueOfEntityType(value)) {
      setSelectedValue(value)
      setSearch(String(value?.[searchKey] ?? ''))
    }

    onChange?.(event, value, reason)
  }

  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setOptions([])

    if (!search) {
      return
    }

    const matchingOption = options.find((option) => {
      const searchValue = onTransformSearch ? onTransformSearch(search) : search
      const optionValue = String(option[searchKey])

      return optionValue === searchValue
    })

    if (matchingOption) {
      onChange?.(
        event,
        matchingOption as Value<Entity, Multiple, false, true>,
        'blur',
      )

      return
    }

    if (selectedValue) {
      return
    }

    setIsOnBlurRequestLoading(true)

    onTriggerSearch(search)
      .then(({ data }) => {
        const matchingOption = data.data.find((option) => {
          const searchValue = onTransformSearch
            ? onTransformSearch(search)
            : search
          const optionValue = String(option[searchKey])

          return optionValue === searchValue
        })

        if (!matchingOption) {
          const notFoundMessage =
            getNotFoundMessage?.(search) ??
            t('atoms.EntityPicker.notFound', {
              search,
            })

          enqueueSnackbar(notFoundMessage, {
            variant: 'error',
          })

          return setSearch('')
        }

        return onChange?.(
          event,
          matchingOption as Value<Entity, Multiple, false, true>,
          'blur',
        )
      })
      .catch((error) => {
        const errorMessage =
          getErrorMessage?.(error) ??
          t('atoms.EntityPicker.defaultErrorMessage')

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      })
      .finally(() => {
        setIsOnBlurRequestLoading(false)
      })
  }

  useEffect(() => {
    if (!props.value) {
      setSearch('')

      return
    }

    if (isValueOfEntityType(props.value)) {
      setSearch(String(props.value[searchKey]))

      if (props.disabled && props.value[searchKey] === null)
        setSearch(String(props.value[fallbackKey]))
      return
    }
  }, [props.value])

  const onPopupIconClick = () => {
    const isPopupOpen = Boolean(options.length)

    if (isPopupOpen) {
      setOptions([])

      return
    }

    loadOptions(search)
  }

  return (
    <StyledSelect<Entity, Multiple, false, true>
      open={Boolean(options.length)}
      onInputChange={onInputTextChange}
      onChange={handleChange}
      options={options}
      loading={isLoadingOptions || isOnBlurRequestLoading}
      popupIcon={
        !popupIcon || isLoadingOptions || isOnBlurRequestLoading ? null : (
          <div onClick={onPopupIconClick}>{popupIcon}</div>
        )
      }
      noOptionsText={noOptionsText ?? t('atoms.EntityPicker.noOptions')}
      filterOptions={(options) => options}
      onInputBlur={handleBlur}
      inputValue={search}
      inputRef={inputRef}
      PopperComponent={(props) => (
        <StyledPopper {...props} placement="bottom-start" />
      )}
      classes={{
        popupIndicator: 'EntityPicker__popupIndicator',
        clearIndicator: 'EntityPicker__clearIndicator',
        ...classes,
      }}
      placeholder={placeholder ?? t('atoms.EntityPicker.placeholder')}
      freeSolo
      {...props}
    />
  )
}

const StyledPopper = styled(Popper)`
  min-width: 15rem;
  z-index: 2000;
`

const StyledSelect = styled(Select)`
  .EntityPicker__popupIndicator {
    transform: none;
  }

  .EntityPicker__clearIndicator {
    display: none;
  }
` as typeof Select

export default EntityPicker
