import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const getCombinedDate = (date: Date, time: Date): Date =>
  dayjs(date)
    .startOf('day')
    .hour(time.getHours())
    .minute(time.getMinutes())
    .toDate()

type Duration = {
  days: number
  hours: number
  minutes: number
}

export const getDurationBetweenDates = (a: Date, b: Date): Duration | null => {
  const dateA = dayjs(a)
  const dateB = dayjs(b)

  const diff = dayjs(dateB).diff(dateA)

  if (diff < 0) {
    return null
  }

  const days = dayjs(dateB).diff(dateA, 'days')

  const hours = dayjs(dateB).subtract(days, 'days').diff(dateA, 'hours')

  const minutes = dayjs(dateB)
    .subtract(days, 'days')
    .subtract(hours, 'hours')
    .diff(dateA, 'minutes')

  return {
    days,
    hours,
    minutes,
  }
}

export const getDurationBetweenDatesInMinutes = (a: Date, b: Date) => {
  return (a.getTime() - b.getTime()) / 60000
}

// TODO refactor using translation keys
export const getFormattedDuration = (durationInMinutes: number) => {
  const target = dayjs.duration({
    minutes: durationInMinutes,
  })

  const hours = Math.floor(target.asHours()).toString().padStart(2, '0')

  const minutes = Math.floor(
    target.subtract(parseInt(hours), 'hour').asMinutes(),
  )
    .toString()
    .padStart(2, '0')

  return `${hours}:${minutes}`
}
