import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { api } from '@app/utils/api/api'
import { LegDetailDto } from '@shared/dto/requests.dto'
import { AirportMapDto } from '@shared/dto/airports.dto'
import { useQuery } from '@tanstack/react-query'
import { LatLngExpression, LatLngTuple } from 'leaflet'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  LayerGroup,
  LayersControl,
  Marker,
  Polyline,
  Popup,
  Tooltip,
  useMap,
  useMapEvents,
} from 'react-leaflet'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ArrowForwardIos } from '@material-ui/icons'
import { AirportIcon } from '@app/components/organisms/RequestMap/Icon'
import {
  LegData,
  ROOT_SELECTOR,
} from '@app/components/organisms/RequestMap/shared'

import { OfferStatuses } from '@shared/enums'
import { useTranslation } from 'react-i18next'
import { LegPathPoints } from '@app/hooks/useMapFlightPoints'
import {
  DATA_LEG_ID_ATTR,
  getLegDirection,
  getMapLegColor,
  getSelectorForLine,
  getSelectorForText,
  getZoom,
  reorderFromTo,
  SELECTORS,
} from '@app/components/organisms/RequestMap/utils'
import { getFormattedPrice } from '@shared/utils/offer.utils'

interface Props {
  legs: LegDetailDto[]
  offerStatus: OfferStatuses
  mapPointsData: LegPathPoints
}

export default function Inside({ legs, offerStatus, mapPointsData }: Props) {
  const map = useMap()
  const [initiated, setInitited] = useState(false)
  // eslint-disable-next-line no-undef
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const legsAirports = useRef<Array<string>>([])
  const { t } = useTranslation()

  setTimeout(() => {
    map.invalidateSize()
  }, 0)

  const data = useMemo((): LegData[] => {
    legsAirports.current = []
    return (
      legs
        // Sort legs ensuring removed legs come after non-removed legs
        .sort((a, b) => {
          return (
            (a.remove_leg === null ? 0 : 1) - (b.remove_leg === null ? 0 : 1)
          )
        })
        .map((leg, i) => {
          const findAirport = ([lat, lng]: LatLngTuple) =>
            leg.departure_airport.latitude === lat &&
            leg.departure_airport.longitude === lng
              ? leg.departure_airport
              : leg.arrival_airport

          const llFrom: LatLngTuple = [
            leg.departure_airport.latitude,
            leg.departure_airport.longitude,
          ]
          const llTo: LatLngTuple = [
            leg.arrival_airport.latitude,
            leg.arrival_airport.longitude,
          ]

          const [from, to] = reorderFromTo(llFrom, llTo)
          const price =
            leg.airport_fee +
            leg.arrival_fee +
            leg.departure_fee +
            leg.variable_cost

          const time = `${Math.floor(leg.duration_in_minutes / 60)}h ${
            leg.duration_in_minutes % 60
          }m`

          legsAirports.current.push(
            findAirport(from).icao_code,
            findAirport(to).icao_code,
          )

          return {
            direction: getLegDirection(llFrom, llTo),
            from: {
              latlng: from,
              name: findAirport(from).name,
              icao: findAirport(from).icao_code,
            },
            to: {
              latlng: to,
              name: findAirport(to).name,
              icao: findAirport(to).icao_code,
            },

            text: `${t('molecules.LegFieldGroup.heading', {
              index: i + 1,
            })} / ${time} / ${Math.round(
              leg.distance_in_nautical_miles,
            )}NM / ${getFormattedPrice(
              price,
              selectedOperator?.currency?.symbol || '',
            )}`,
            legId: leg.id,
          }
        })
    )
  }, [legs])

  useEffect(() => {
    const allBounds: LatLngTuple[] = []
    data.forEach((d) => {
      allBounds.push(d.from.latlng, d.to.latlng)
    })
    const uniqueBounds = allBounds.filter(
      (value, index, self) =>
        self.findIndex((v) => v[0] === value[0] && v[1] === value[1]) === index,
    )

    setTimeout(() => {
      map
        .invalidateSize()
        .flyToBounds(uniqueBounds)
        .setZoom(getZoom(legs.map((leg) => leg.distance_in_nautical_miles)))
    }, 0)
  }, [data])

  const [renderAirports, setRenderAirports] = useState(false)

  const [currentZoom, setCurrentZoom] = useState(map.getZoom())
  const selectedOperator = useSelector(selectSelectedOperator)
  const [airportsQueryKey, setAirportsQueryKey] = useState<
    Array<string | number>
  >([])

  const rerenderTexts = useCallback(() => {
    const rootEl = document.querySelector(`.${ROOT_SELECTOR}`)
    if (!rootEl) {
      return
    }
    const rootRect = rootEl.getBoundingClientRect()
    const paths = Array.from(
      document.querySelectorAll(`.${SELECTORS.FLIGHT_LINE}`),
    )

    const pathsTemp: Record<string, Array<number>> = {}

    paths.forEach((path) => {
      const d = path.getAttribute('d') || ''
      pathsTemp[d] = (pathsTemp[d] || []).concat(
        Number(path.getAttribute(DATA_LEG_ID_ATTR)),
      )
    })

    Array.from(document.querySelectorAll(`.${SELECTORS.TEXT_LAYER}`)).forEach(
      (text) => text.remove(),
    )

    Object.values(pathsTemp).forEach((ids) => {
      const textContent = ids
        .map((id) => {
          const trip = data.find((trip) => trip.legId === id)

          const arrow = trip?.direction === 'left' ? '<<' : '>>'
          return `${arrow} ${trip?.text.split('/')[0].trim()} ${arrow}`
        })
        .join(' | ')

      const path = document.querySelector(`#${getSelectorForLine(ids[0])}`)

      const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text',
      )
      text.style.fontSize = '14px'
      const textSelector = getSelectorForText(ids[0])
      text.classList.add(SELECTORS.TEXT_LAYER, textSelector)
      const textPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'textPath',
      )
      textPath.textContent = textContent
      text.style.transform = 'translate(2px, -5px)'
      text.style.fontWeight = '700'
      text.style.textShadow = '0 0 5px white'

      textPath.setAttribute('href', `#${getSelectorForLine(ids[0])}`)
      text.appendChild(textPath)
      path?.parentElement?.appendChild(text)

      let offset = 5
      while (offset < 90) {
        textPath.setAttribute('startOffset', `${offset}%`)

        const textRect = text.getBoundingClientRect()
        const textLeft = textRect.left - rootRect.left
        const textTop = textRect.top - rootRect.top
        const textBot = textRect.bottom - rootRect.bottom

        if (textLeft > 10 && textTop > 10 && textBot < 0) {
          break
        }

        offset += 5
      }
    })
  }, [data])

  const [visibleLegs, setVisibleLegs] = useState<Array<number>>(
    data.map((trip) => trip.legId),
  )

  const [showTextsPane, setShowTextsPane] = useState(true)

  const regenerateAirportsQueryKey = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      const bounds = map.getBounds()
      const coords = [
        bounds.getSouth(),
        bounds.getNorth(),
        bounds.getWest(),
        bounds.getEast(),
      ]
      setAirportsQueryKey((state) => {
        return state.length === 6 &&
          state.slice(2).every((c, i) => c <= coords[i])
          ? state
          : ['airports', 'map', ...coords]
      })
    }, 600)
  }, [])

  const { data: airports } = useQuery({
    queryKey: airportsQueryKey,
    queryFn: (ctx) =>
      api.getMapAirports({
        lat_from: ctx.queryKey[2] as number,
        lat_to: ctx.queryKey[3] as number,
        lon_from: ctx.queryKey[4] as number,
        lon_to: ctx.queryKey[5] as number,
      }),
    enabled: initiated,
    onSuccess: () => {
      setRenderAirports(true)
    },
  })

  useMapEvents({
    movestart: () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    },
    moveend: () => {
      rerenderTexts()
      regenerateAirportsQueryKey()
    },
    zoomend: () => {
      rerenderTexts()
      setCurrentZoom(map.getZoom())
    },
    layeradd: () => {
      data.forEach((trip) => {
        const line = document.querySelector(
          `.${getSelectorForLine(trip.legId)}`,
        )
        if (!line || line.hasAttribute('id')) {
          return
        }
        setVisibleLegs((state) => [...state, trip.legId])
        line.setAttribute('id', getSelectorForLine(trip.legId))
        line.setAttribute(DATA_LEG_ID_ATTR, trip.legId.toString())
      })
      rerenderTexts()
    },
    layerremove: () => {
      data.forEach((trip) => {
        const line = document.querySelector(
          `.${getSelectorForLine(trip.legId)}`,
        )
        if (!line) {
          setVisibleLegs((state) => state.filter((id) => id !== trip.legId))
          return
        }
      })
      rerenderTexts()
    },
  })

  // ONMOUNT
  useEffect(() => {
    const getLeg = (
      size: 'min' | 'max',
      ...legs: LatLngTuple[]
    ): LatLngTuple => {
      const lat = Math[size].apply(
        null,
        legs.map(([lat]) => lat),
      )
      const lon = Math[size].apply(
        null,
        legs.map(([, lon]) => lon),
      )
      return [lat, lon]
    }

    const smallest = data.reduce(
      (acc, trip) => {
        const out = { ...acc }
        out.from = getLeg(
          'min',
          acc.from,
          trip.from.latlng,
          acc.to,
          trip.to.latlng,
        )
        out.to = getLeg(
          'max',
          acc.from,
          trip.from.latlng,
          acc.to,
          trip.to.latlng,
        )
        return out
      },
      {
        from: data[0].from.latlng,
        to: data[0].to.latlng,
      },
    )

    let textsAreDone = false

    const interval = setInterval(() => {
      if (textsAreDone) {
        clearInterval(interval)
        return
      }

      const firstPath = document.querySelector(`.${SELECTORS.FLIGHT_LINE}`)
      if (!firstPath) {
        return
      }

      rerenderTexts()
      textsAreDone = true
    }, 500)

    map.fitBounds([smallest.from, smallest.to])
    setInitited(true)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const aiportsLayer = useMemo(() => {
    return (
      <LayersControl.Overlay name="Airports" checked>
        <LayerGroup>
          {(currentZoom < 9 ? [] : airports?.data || []).map(
            (a: AirportMapDto) =>
              !legsAirports.current.includes(a.icao_code) ? (
                <Marker
                  key={a.icao_code}
                  position={[a.latitude, a.longitude]}
                  icon={AirportIcon()}
                >
                  <Popup>{a.name}</Popup>
                  <Tooltip permanent>{a.icao_code}</Tooltip>
                </Marker>
              ) : null,
          )}
        </LayerGroup>
      </LayersControl.Overlay>
    )
  }, [renderAirports, airports, currentZoom])

  const linesLayer = useMemo(() => {
    return data.map((trip, i) => {
      const leg = legs.find((l) => l.id === trip.legId)

      return (
        <LayersControl.Overlay name={`Leg ${i + 1}`} key={trip.legId} checked>
          <LayerGroup>
            <Marker key={trip.from.icao} position={trip.from.latlng}>
              <Popup>{trip.from.name}</Popup>
              <Tooltip opacity={0.7} permanent>
                {trip.from.icao}
              </Tooltip>
            </Marker>
            <Marker key={trip.to.icao} position={trip.to.latlng}>
              <Popup>{trip.to.name}</Popup>
              <Tooltip opacity={0.7} permanent>
                {trip.to.icao}
              </Tooltip>
            </Marker>
            <Polyline
              noClip
              pathOptions={{
                color: getMapLegColor(leg, offerStatus),
              }}
              positions={mapPointsData[trip.legId] as LatLngExpression[]}
            />
          </LayerGroup>
        </LayersControl.Overlay>
      )
    })
  }, [data, legs, mapPointsData])

  return (
    <>
      <LayersControl position="topright">
        <>
          {linesLayer}
          {aiportsLayer}
        </>
      </LayersControl>
      <TextsWrapper
        style={{
          maxWidth: showTextsPane ? '500px' : '30px',
          maxHeight: showTextsPane ? '500px' : '30px',
          visibility: visibleLegs.length > 0 ? 'visible' : 'hidden',
          opacity: visibleLegs.length > 0 ? '1' : '0',
          padding: showTextsPane ? '8px' : '3px',
        }}
      >
        <TextsHeading
          style={{
            width: showTextsPane ? 'auto' : '0px',
          }}
        >
          {t('organisms.RequestMap.legend')}
        </TextsHeading>
        {data.map((trip) => {
          const color = getMapLegColor(
            legs.find((l) => l.id === trip.legId),
            offerStatus,
          )
          return (
            <TextItem
              key={trip.legId}
              style={{
                opacity: showTextsPane ? '1' : '0',
                maxHeight: visibleLegs.includes(trip.legId) ? '40px' : '0px',
                borderColor: color,
                borderBottomWidth:
                  visibleLegs.includes(trip.legId) && showTextsPane
                    ? '2px'
                    : '0px',
                borderLeftWidth:
                  visibleLegs.includes(trip.legId) && showTextsPane
                    ? '8px'
                    : '0px',
              }}
            >
              {trip.text}
            </TextItem>
          )
        })}
        <IconsWrapper
          style={{
            top: showTextsPane ? '8px' : '4px',
            left: showTextsPane ? '8px' : '4px',
          }}
        >
          <ArrowForwardIos
            onClick={() => setShowTextsPane((state) => !state)}
            fontSize="small"
            style={{
              transform: `rotate(${showTextsPane ? '180deg' : '0deg'})`,
            }}
          />
        </IconsWrapper>
      </TextsWrapper>
    </>
  )
}

const TextsWrapper = styled.div`
  position: absolute;
  top: 100px;
  left: 12px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`

const IconsWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 24px;
  transition: all 0.5s;

  & > svg {
    transition: all 0.5s;
    cursor: pointer;
  }
`

const TextItem = styled.div`
  font-size: 16px;
  transition: all 0.5s;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 2px solid white;
  border-left-style: solid;
  padding-left: 4px;
`

const TextsHeading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  overflow: hidden;
`
