/**
 * @todo Add docs
 */

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

type CombinedLegJuncture = {
  arrival_date: string | Date
  departure_date: string | Date
}

type DateLegJuncture = {
  arrival_date: Date
  departure_date: Date
}

type StringLegJuncture = {
  arrival_date: string
  departure_date: string
}

export function castLegJunctureToStrings<T>(
  input: T & CombinedLegJuncture,
): Omit<T, 'departure_date' | 'arrival_date'> & StringLegJuncture {
  return {
    ...input,

    departure_date: dayjs.utc(input.departure_date).format(),
    arrival_date: dayjs.utc(input.arrival_date).format(),
  }
}

export function castLegJunctureToDates<T>(
  input: T & CombinedLegJuncture,
): Omit<T, 'departure_date' | 'arrival_date'> & DateLegJuncture {
  return {
    ...input,

    departure_date: dayjs.utc(input.departure_date).toDate(),
    arrival_date: dayjs.utc(input.arrival_date).toDate(),
  }
}

export function assertNumberFinite(value: number): asserts value is number {
  if (!Number.isFinite(value)) {
    throw new Error(`Value must be a finite number but got '${value}' instead`)
  }
}
