import { Actions, UserRoles } from '@shared/enums'
import { PERMISSIONS, USER_ROLE_WEIGHTS } from '@shared/constants'

interface UserRole {
  operator_id: number
  role: UserRoles
}

export const userHasRights = (action: Actions, userRoles: UserRoles) => {
  const permissions = PERMISSIONS.get(action)
  return !permissions || permissions.includes(userRoles)
}

export const getIsRoleSuperior = (
  initiatorRole: UserRoles,
  comparedRole: UserRoles,
) => USER_ROLE_WEIGHTS[comparedRole] < USER_ROLE_WEIGHTS[initiatorRole]

export const userIsOperator = (userRole: UserRole, operatorId: number) =>
  userRole.operator_id !== operatorId

interface HasOperatorId {
  operator_id: number
}

export function getUserRoleForOperator<T extends HasOperatorId>(
  userRoles: T[],
  operatorId: number,
): T {
  const userRole = userRoles.find(
    (userRole) => userRole.operator_id === operatorId,
  )

  if (!userRole) {
    throw new Error(`user role for operator '${operatorId}' doesn't exist`)
  }

  return userRole
}
