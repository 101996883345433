import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'

import Typography from '@app/components/atoms/Typography/Typography'
import RequestSidebarSection from '@app/components/organisms/RequestDetail/RequestSidebarSection/RequestSidebarSection'
import SimilarRequestRow from '@app/components/organisms/SimilarRequestsSection/SimilarRequestRow'
import { RequestDetailDto } from '@shared/dto'

export type SimilarRequestsSectionProps = {
  data?: RequestDetailDto[]
  isLoading?: boolean
  isError?: boolean
}

const SimilarRequestsSection = ({
  data,
  isLoading,
  isError,
}: SimilarRequestsSectionProps) => {
  const { t } = useTranslation()

  return (
    <RequestSidebarSection
      isLoading={isLoading}
      isError={isError}
      heading={t('organisms.SimilarRequestsSection.heading')}
      errorMessage={t('organisms.SimilarRequestsSection.errorMessage')}
    >
      {data?.length === 0 ? (
        <EmptyListMessage>
          {t('organisms.SimilarRequestsSection.emptyMessage')}
        </EmptyListMessage>
      ) : (
        data?.map((similarRequest, index) => (
          <Box key={index} mt={index === 0 ? '0' : '1rem'}>
            <SimilarRequestRow data={similarRequest} />
          </Box>
        ))
      )}
    </RequestSidebarSection>
  )
}

/**
 * @todo [refactor] create Typography variant
 */
const EmptyListMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 0.8rem;
  text-align: center;
`

export { useSimilarRequestSectionProps } from '@app/components/organisms/SimilarRequestsSection/useSimilarRequestSectionProps'

export default SimilarRequestsSection
