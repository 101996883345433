import React, { ChangeEvent, FocusEventHandler, useCallback } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import FilterItem from '@app/components/atoms/FilterItem/FilterItem'

import EntityPicker, {
  EntityPickerProps,
  EntityPickerValue,
} from '@app/components/atoms/EntityPicker/EntityPicker'

import { api } from '@app/utils/api/api'
import { CountryDto } from '@shared/dto/country.dto'

export interface CountryPickerProps<
  Multiple extends boolean | undefined = undefined,
> extends Omit<
    EntityPickerProps<CountryDto, Multiple>,
    | 'searchKey'
    | 'getOptionLabel'
    | 'renderOption'
    | 'getNotFoundMessage'
    | 'getErrorMessage'
    | 'onTriggerSearch'
  > {
  onBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
  >
}

const CountryPicker = <Multiple extends boolean | undefined = false>({
  placeholder,
  noOptionsText,
  ...props
}: CountryPickerProps<Multiple>): JSX.Element => {
  const { t } = useTranslation()

  const getOptionLabel = (row: CountryDto) => `${row.name} (${row.code})`
  const renderOption = (row: CountryDto) => `${row.name} (${row.code})`

  const renderTags = useCallback(
    (tags: CountryDto[]) => {
      return tags.map((tag) => (
        <StyledFilterItem
          key={tag.id}
          label={tag.name}
          onDelete={(event) => handleClearItem(event, tag)}
        />
      ))
    },
    [props.value, props.onChange],
  )

  const handleClearItem = (
    event: ChangeEvent<object>,
    currency: CountryDto,
  ) => {
    const getIsValueArrayOfEntityType = (
      value: EntityPickerValue<CountryDto, boolean>,
    ): value is CountryDto[] =>
      !!(value as CountryDto[])?.length &&
      typeof (value as CountryDto[])?.[0] !== 'string'

    if (!props.onChange || !props.value) {
      return
    }

    if (getIsValueArrayOfEntityType(props.value)) {
      props.onChange(
        event,
        props.value.filter(
          (item) => item.id !== currency.id,
        ) as EntityPickerValue<CountryDto, Multiple>,
        'remove-option',
      )
    }
  }

  const handleSearch = (search: string) => {
    return api.listCountries({
      name: search,
    })
  }

  return (
    <EntityPicker<CountryDto, Multiple>
      searchKey="name"
      onTriggerSearch={handleSearch}
      getErrorMessage={() => t('atoms.CountryPicker.defaultErrorMessage')}
      placeholder={placeholder ?? t('atoms.CountryPicker.placeholder')}
      noOptionsText={noOptionsText ?? t('atoms.CountryPicker.noOptions')}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderTags={props.multiple ? renderTags : undefined}
      getNotFoundMessage={(search: string) =>
        t('atoms.CountryPicker.countryNotFound', {
          name: search,
        })
      }
      {...props}
    />
  )
}

const StyledFilterItem = styled(FilterItem)`
  height: 100%;
  margin-right: 0.5rem;
  min-height: 1.5rem;
`

export default CountryPicker
