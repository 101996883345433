import React, {
  ChangeEvent,
  FocusEventHandler,
  useCallback,
  useMemo,
} from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Flight } from '@material-ui/icons'

import FilterItem from '@app/components/atoms/FilterItem/FilterItem'

import EntityPicker, {
  EntityPickerProps,
  EntityPickerValue,
} from '@app/components/atoms/EntityPicker/EntityPicker'

import { api } from '@app/utils/api/api'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'

export enum AircraftPickerVariants {
  Condensed = 'Condensed',
  Regular = 'Regular',
}

export interface AircraftPickerProps<
  Multiple extends boolean | undefined = undefined,
> extends Omit<
    EntityPickerProps<AircraftDetailDto, Multiple>,
    | 'searchKey'
    | 'availableItems'
    | 'getOptionLabel'
    | 'renderOption'
    | 'variant'
    | 'getNotFoundMessage'
    | 'popupIcon'
    | 'getErrorMessage'
    | 'onTriggerSearch'
  > {
  availableAircraft?: AircraftDetailDto[]
  variant?: AircraftPickerVariants
  onBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
  >
}

const AircraftPicker = <Multiple extends boolean = false>({
  availableAircraft,
  placeholder,
  noOptionsText,
  variant = AircraftPickerVariants.Regular,
  ...props
}: AircraftPickerProps<Multiple>): JSX.Element => {
  const { t } = useTranslation()

  const getOptionLabel = useCallback(
    (row: AircraftDetailDto) => {
      switch (variant) {
        case AircraftPickerVariants.Condensed:
          return row.registration_code

        case AircraftPickerVariants.Regular:
        default:
          return `${row.registration_code} (${row.manufacturer} ${row.type})`
      }
    },
    [variant],
  )

  const handleClearItem = (
    event: ChangeEvent<object>,
    aircraft: AircraftDetailDto,
  ) => {
    const getIsValueArrayOfEntityType = (
      value: EntityPickerValue<AircraftDetailDto, boolean>,
    ): value is AircraftDetailDto[] =>
      !!(value as AircraftDetailDto[])?.length &&
      typeof (value as AircraftDetailDto[])?.[0] !== 'string'

    if (!props.onChange || !props.value) {
      return
    }

    if (getIsValueArrayOfEntityType(props.value)) {
      props.onChange(
        event,
        props.value.filter(
          (item) => item.id !== aircraft.id,
        ) as EntityPickerValue<AircraftDetailDto, Multiple>,
        'remove-option',
      )
    }
  }

  const renderTags = useCallback(
    (tags: AircraftDetailDto[]) => {
      return tags.map((tag) => {
        switch (variant) {
          case AircraftPickerVariants.Condensed:
            return (
              <StyledFilterItem
                key={tag.id}
                label={tag.registration_code}
                onDelete={(event) => handleClearItem(event, tag)}
              />
            )

          case AircraftPickerVariants.Regular:
          default:
            return (
              <StyledFilterItem
                key={tag.id}
                label={tag.registration_code}
                onDelete={(event) => handleClearItem(event, tag)}
              />
            )
        }
      })
    },
    [props.value, props.onChange, variant],
  )

  const renderOption = useCallback(
    (row: AircraftDetailDto) => {
      switch (variant) {
        case AircraftPickerVariants.Condensed:
          return row.registration_code

        case AircraftPickerVariants.Regular:
        default:
          return (
            <>
              <b>{row.registration_code}</b>&nbsp;({row.manufacturer} {row.type}
              )
            </>
          )
      }
    },
    [variant],
  )

  const shouldForcePopupIcon = useMemo(() => {
    switch (variant) {
      case AircraftPickerVariants.Condensed:
        return true

      case AircraftPickerVariants.Regular:
      default:
        return false
    }
  }, [variant])

  const handleSearch = (search: string) => {
    /**
     * @todo Race conditions
     */
    return api.listAircraft({
      registration_code: search,
    })
  }

  return (
    <EntityPicker<AircraftDetailDto, Multiple>
      searchKey="registration_code"
      onTriggerSearch={handleSearch}
      availableItems={availableAircraft}
      getNotFoundMessage={(search: string) =>
        t('atoms.AircraftPicker.aircraftNotFound', {
          registrationCode: search,
        })
      }
      getErrorMessage={() => t('atoms.AircraftPicker.defaultErrorMessage')}
      placeholder={placeholder ?? t('atoms.AircraftPicker.placeholder')}
      noOptionsText={noOptionsText ?? t('atoms.AircraftPicker.noOptions')}
      popupIcon={
        variant === AircraftPickerVariants.Condensed ? (
          <StyledFlightIcon />
        ) : undefined
      }
      forcePopupIcon={shouldForcePopupIcon}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderTags={props.multiple ? renderTags : undefined}
      {...props}
    />
  )
}

// @todo How to reuse InputEndAdornment component?
const StyledFlightIcon = styled(Flight)`
  width: 1.25rem;
`

const StyledFilterItem = styled(FilterItem)`
  height: 100%;
  margin-right: 0.5rem;
  min-height: 1.5rem;
`

export default AircraftPicker
