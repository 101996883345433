import React, { useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'

import LegFieldGroup from '@app/components/organisms/LegEditorForm/LegFieldGroup/LegFieldGroup'

import { useActiveUser } from '@app/hooks/useActiveUser'
import { getEditorDefaultValues } from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'

import { BaseLegDetailDto, OfferDto } from '@shared/dto/requests.dto'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { DisplayTimeTypes, OfferStatuses } from '@shared/enums'

import Box from '@material-ui/core/Box'
import Button from '@app/components/atoms/Button/Button'

import {
  getLocalDateIgnoringTimezone,
  getTimezoneAwareDateFromUTC,
} from '@app/utils/dateUtils'

dayjs.extend(utc)

interface RemovedLegsListProps {
  groupType: 'cancelled' | 'optimized' | 'calculated'
  offer: OfferDto
  legs: BaseLegDetailDto[]
  aircraft: AircraftDetailDto
  onIgnoreLegsFromOptimization?: (removeLegIds: number[]) => void
}

const RemovedLegsList = ({
  groupType,
  legs,
  aircraft,
  offer,
  onIgnoreLegsFromOptimization,
}: RemovedLegsListProps) => {
  const activeUser = useActiveUser()
  const { t } = useTranslation()
  const [ignoreLegIds, setIgnoreLegIds] = useState<number[]>([])
  const values = getEditorDefaultValues(legs, aircraft, {
    timeDisplay: activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
  })

  const canIgnoreLegs = [
    OfferStatuses.New,
    OfferStatuses.Unhandled,
    OfferStatuses.Draft,
  ].includes(offer.status)

  const onIgnoreLegFromOptimizationSwitch = (legId: number) => {
    if (ignoreLegIds.includes(legId)) {
      setIgnoreLegIds(ignoreLegIds.filter((curLegId) => curLegId !== legId))
    } else {
      setIgnoreLegIds([legId, ...ignoreLegIds])
    }
  }

  const onIgnoreLegsFromOptimizationClick = () => {
    if (onIgnoreLegsFromOptimization) {
      onIgnoreLegsFromOptimization(ignoreLegIds)
    }
    setIgnoreLegIds([])
  }

  const onIgnoreAllLegsFromOptimizationClick = () => {
    if (onIgnoreLegsFromOptimization) {
      onIgnoreLegsFromOptimization(
        legs
          .map((curLeg) => curLeg.remove_leg_id)
          .filter((curLeg) => curLeg !== null),
      )
    }
    setIgnoreLegIds([])
  }

  return (
    <>
      {legs.map((leg, index) => (
        <LegFieldGroup
          groupType={groupType}
          leg={values.legs[index]}
          isDeleted
          readonly
          key={`${leg.departure_airport_id}-${leg.arrival_airport_id}-${index}`}
          onIgnoreLegFromOptimizationSwitch={onIgnoreLegFromOptimizationSwitch}
          extras={leg}
          index={index}
          offer={offer}
          fields={{
            type: { value: leg.type },
            arrivalAirport: { value: leg.arrival_airport },
            departureAirport: { value: leg.departure_airport },
            passengerCount: { value: leg.passenger_count },
            aircraft: { value: aircraft },
            departureDate: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.departure_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
            departureTime: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.departure_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
            arrivalDate: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.arrival_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
            arrivalTime: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.arrival_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
          }}
        />
      ))}

      {groupType === 'optimized' && canIgnoreLegs && (
        <Box display="flex" justifyContent="flex-end">
          <Box display="flex">
            <>
              <Box mr="1rem">
                <Button
                  disabled={!ignoreLegIds.length}
                  onClick={onIgnoreLegsFromOptimizationClick}
                  data-testid="LegEditorForm__ignore-legs"
                >
                  {t('organisms.LegEditorForm.ignoreLegs')}
                </Button>
              </Box>

              <Box mr="1rem">
                <Button
                  onClick={onIgnoreAllLegsFromOptimizationClick}
                  type="submit"
                  data-testid="LegEditorForm__ignore-all-legs"
                >
                  {t('organisms.LegEditorForm.ignoreAllLegs')}
                </Button>
              </Box>
            </>
          </Box>
        </Box>
      )}
    </>
  )
}

export default RemovedLegsList
