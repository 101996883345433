import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import EmptyListMessage from '@app/components/atoms/EmptyListMessage/EmptyListMessage'

import DataGrid, {
  DataGridProps,
} from '@app/components/atoms/DataGrid/DataGrid'

import { OrderBy } from '@shared/types'
import { getErrorMessage } from '@app/utils/errorHandling'
import { OrderDirection } from '@app/constants'
import { ContextMenuItem } from '@app/components/atoms/DataGrid/useRowActionColumn'

import { DEFAULT_ORDER_DIRECTION } from '@shared/constants'

export interface GridViewProps<ItemType extends { id: number }>
  extends Pick<
    DataGridProps<ItemType>,
    | 'columns'
    | 'getCollapsedContainer'
    | 'collapsedRowId'
    | 'onTableRowClick'
    | 'onNextDataRequested'
    | 'getContextMenuItems'
    | 'dataTestIdNamespace'
    | 'getBadgeNotificationContent'
    | 'onExportButtonClick'
    | 'isExportable'
  > {
  data: DataGridProps<ItemType>['data'] | null
  isLoading: boolean
  error: unknown
  total: number | null
  orderDirection: OrderDirection | null
  orderBy: OrderBy<ItemType> | null
  noDataContent?: JSX.Element
  errorContent?: JSX.Element
  getContextMenu?: (entity: ItemType) => ContextMenuItem[] | undefined
  defaultOrderBy?: string
  onSortChange: (sort: {
    orderBy: OrderBy<ItemType>
    orderDirection: OrderDirection
  }) => void
}

const GridView = <ItemType extends { id: number }>({
  isLoading,
  error,
  data,
  columns,
  total,
  onNextDataRequested,
  orderBy,
  orderDirection,
  onSortChange,
  getCollapsedContainer,
  collapsedRowId,
  onTableRowClick,
  noDataContent,
  errorContent,
  getContextMenu,
  dataTestIdNamespace,
  getBadgeNotificationContent,
  onExportButtonClick,
  isExportable,
  defaultOrderBy = 'created_at', // @todo [refactor] Deprecate
  ...props
}: GridViewProps<ItemType>): JSX.Element => {
  const { t } = useTranslation()

  const sort = useMemo(() => {
    if (!orderBy || !orderDirection) {
      return null
    }

    return {
      direction: orderDirection.toLowerCase() as 'asc' | 'desc',
      id: orderBy as string,
    }
  }, [orderBy, orderDirection])

  const errorMessage = useMemo(
    () =>
      getErrorMessage(error, {
        404: t('molecules.GridView.404'),
        default: t('molecules.GridView.defaultError'),
      }),
    [error],
  )

  const handleSortChange: DataGridProps<ItemType>['onSortChange'] = (
    nextSort,
  ) => {
    if (!nextSort) {
      return onSortChange({
        orderBy: defaultOrderBy as keyof ItemType,
        orderDirection: DEFAULT_ORDER_DIRECTION.toUpperCase(),
      })
    }

    onSortChange({
      orderBy: nextSort.id as keyof ItemType,
      orderDirection: nextSort.direction.toUpperCase() as OrderDirection,
    })
  }

  if (error) {
    return (
      <EmptyListMessageContainer>
        {errorContent ?? <EmptyListMessage title={errorMessage ?? undefined} />}
      </EmptyListMessageContainer>
    )
  }

  if (!data && isLoading) {
    return (
      <LoaderContainer container alignItems="center" justifyContent="center">
        <CircularProgress size={20} />
      </LoaderContainer>
    )
  }

  if (!data || !data.length) {
    return (
      <EmptyListMessageContainer>
        {noDataContent ?? <EmptyListMessage />}
      </EmptyListMessageContainer>
    )
  }

  return (
    <>
      <DataGrid<ItemType>
        isExportable={isExportable}
        onExportButtonClick={onExportButtonClick}
        getCollapsedContainer={getCollapsedContainer}
        collapsedRowId={collapsedRowId}
        onTableRowClick={onTableRowClick}
        columns={columns}
        data={data}
        hasMore={Boolean(total && data.length < total)}
        onNextDataRequested={onNextDataRequested}
        sort={sort}
        onSortChange={handleSortChange}
        getContextMenuItems={getContextMenu}
        getBadgeNotificationContent={getBadgeNotificationContent}
        dataTestIdNamespace={dataTestIdNamespace}
        {...props}
      />
    </>
  )
}

const LoaderContainer = styled(Grid)`
  height: 3rem;
`

const EmptyListMessageContainer = styled.div`
  margin: 4rem auto;
  display: flex;
  justify-content: center;
`

export default GridView
