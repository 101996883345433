import { CellProps } from 'react-table'
import { PartialRequestDto } from '@shared/dto/requests.dto'
import { useTranslation } from 'react-i18next'
import Typography from '@app/components/atoms/Typography/Typography'
import React from 'react'
import {
  getTripIdWithoutPrefix,
  getTripIdSource,
} from '@shared/utils/request.utils'

export const TripIdCell = ({
  cell,
}: CellProps<PartialRequestDto, undefined>) => {
  const { t } = useTranslation()

  const title =
    getTripIdSource(cell.row.original.trip_id) === 'AVN'
      ? t('molecules.RequestsGridView.tripIdTitle', {
          trip_id: getTripIdWithoutPrefix(cell.row.original.trip_id),
        })
      : undefined

  return (
    <Typography variant="content" title={title}>
      {cell.row.original.trip_id}
    </Typography>
  )
}
