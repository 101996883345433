import React from 'react'
import { useTranslation } from 'react-i18next'
import format from 'number-format.js'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import GroupIcon from '@material-ui/icons/Group'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'

import OfferSummaryItem from '@app/components/organisms/RequestDetail/OfferSummaryItem/OfferSummaryItem'

import { useGetFormattedDuration } from '@app/hooks/useGetFormattedDuration'
import { OfferDto } from '@shared/dto/requests.dto'
import { LegTypes } from '@shared/enums'

import { DEFAULT_NUMBER_FORMAT } from '@shared/constants'

import {
  getLegsDurationInMinutes,
  getLegsOfType,
  getOccupiedLegs,
} from '@shared/utils/leg.utils'
import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

interface OfferSummaryProps {
  offer: OfferDto
  totalProfit: number
  aircraftRevenue: number
}

const OfferSummary = ({
  offer,
  totalProfit,
  aircraftRevenue,
}: OfferSummaryProps) => {
  const { t } = useTranslation()

  const selectedOperator = useSelector(selectSelectedOperator)

  const getFormattedDuration = useGetFormattedDuration()

  const formattedOccupiedLegsFlightTime = getFormattedDuration(
    getLegsDurationInMinutes(getOccupiedLegs(offer.legs)),
  )

  const formattedEmptyLegsFlightTime = getFormattedDuration(
    getLegsDurationInMinutes(
      getLegsOfType(offer.legs, [LegTypes.Empty, LegTypes.Ferry]),
    ),
  )

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <OfferSummaryItem
        icon={<GroupIcon fontSize="large" />}
        title={t('organisms.OfferSummary.occupiedFlightTimeTitle')}
        value={<StyledSpan>{formattedOccupiedLegsFlightTime}</StyledSpan>}
      />
      <OfferSummaryItem
        icon={<AirplanemodeActiveIcon fontSize="large" />}
        title={t('organisms.OfferSummary.emptyFlightTimeTitle')}
        value={<StyledSpan>{formattedEmptyLegsFlightTime}</StyledSpan>}
      />
      <OfferSummaryItem
        icon={<AttachMoneyIcon fontSize="large" />}
        title={t('organisms.OfferSummary.profitTitle')}
        value={
          <>
            <StyledSpan>
              {format(DEFAULT_NUMBER_FORMAT, totalProfit)}
            </StyledSpan>{' '}
            {selectedOperator?.currency?.symbol}
          </>
        }
      />
      <OfferSummaryItem
        icon={<LocalAtmIcon fontSize="large" />}
        title={t('organisms.OfferSummary.revenueTitle')}
        value={
          <>
            <StyledSpan>
              {format(DEFAULT_NUMBER_FORMAT, aircraftRevenue)}
            </StyledSpan>{' '}
            {selectedOperator?.currency?.symbol}
          </>
        }
      />
    </Box>
  )
}

const StyledSpan = styled.span`
  font-weight: bold;
`

export default OfferSummary
