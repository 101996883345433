import { AircraftDetailDto, LegDetailDto, ScheduleDetailDto } from '@shared/dto'

/**
 * Validates that airports of all schedule legs are connecting and that there
 * are no overlaps
 *
 * You can also check that schedule starts / ends in the aircraft base airport
 * by providing 'mustStartInBase' and / or 'mustEndInBase' options
 *
 * @todo [Tech] Consider creating a method that will return each error instead
 * of just boolean value so that it can be reused in the leg editor as well
 *
 * @returns Whether given schedule is valid
 */
export function getIsScheduleValid(
  schedule: Pick<
    ScheduleDetailDto,
    | 'departure_airport_id'
    | 'arrival_airport_id'
    | 'departure_date'
    | 'arrival_date'
  >[],
  aircraft?: Pick<AircraftDetailDto, 'base_airport_id'>,
  options?: {
    mustStartInBase: boolean
    mustEndInBase: boolean
  },
): boolean {
  if (schedule.length === 0) {
    return true
  }

  if (options?.mustStartInBase) {
    if (!aircraft?.base_airport_id) {
      throw new Error(
        `Aircraft must be provided when 'mustStartInBase' is set to true`,
      )
    }

    const firstScheduleItem = schedule[0]

    if (firstScheduleItem.departure_airport_id !== aircraft?.base_airport_id) {
      return false
    }
  }

  if (options?.mustEndInBase) {
    if (!aircraft?.base_airport_id) {
      throw new Error(
        `Aircraft must be provided when 'mustEndInBase' is set to true`,
      )
    }

    const lastScheduleItem = schedule[schedule.length - 1]

    if (lastScheduleItem.arrival_airport_id !== aircraft?.base_airport_id) {
      return false
    }
  }

  for (let i = 0, j = schedule.length; i < j; i++) {
    const previousScheduleItem = schedule[i - 1]
    const currentScheduleItem = schedule[i]

    if (!previousScheduleItem) {
      continue
    }

    if (
      previousScheduleItem.arrival_airport_id !==
      currentScheduleItem.departure_airport_id
    ) {
      return false
    }

    if (
      new Date(currentScheduleItem.departure_date).getTime() <
      new Date(previousScheduleItem.arrival_date).getTime()
    ) {
      return false
    }
  }

  return true
}

/**
 * @returns Schedule without given removed legs
 *
 * @example
 *
 * const schedule = [
 *   { id: 1 },
 *   { id: 2 },
 *   { id: 3 },
 *   { id: 4 },
 * ]
 *
 * const legs = [
 *   { remove_leg_id: 1 },
 *   { remove_leg_id: 3 },
 * ]
 *
 * getScheduleWithRemovedLegsOmitted(schedule, legs)
 *
 * // [
 * //   { id: 2 },
 * //   { id: 4 },
 * // ]
 */
export function getScheduleWithRemovedLegsOmitted<
  T extends Pick<ScheduleDetailDto, 'id'>,
>(schedule: T[], removedLegs: Pick<LegDetailDto, 'remove_leg_id'>[]): T[] {
  const removedScheduleIds = removedLegs.map((leg) => leg.remove_leg_id)

  return schedule.filter((item) => !removedScheduleIds.includes(item.id))
}

type ScheduleCompareItem = Pick<
  ScheduleDetailDto,
  | 'type'
  | 'departure_date'
  | 'arrival_date'
  | 'departure_airport_id'
  | 'arrival_airport_id'
>

/**
 * Compares type, departure date, departure airport, arrival date and arrival
 * airport of each leg in given schedules
 *
 * @returns Whether given schedules are identical
 *
 * @example
 *
 * const scheduleOne = [
 *   {
 *     type: 'empty',
 *     departure_date: '2022-01-01 00:00',
 *     departure_airport_id: 1,
 *     arrival_date: '2022-01-01 01:30',
 *     arrival_airport_id: 2,
 *   },
 *   {
 *     type: 'occupied',
 *     departure_date: '2022-01-01 03:00',
 *     departure_airport_id: 2,
 *     arrival_date: '2022-01-01 04:30',
 *     arrival_airport_id: 3,
 *   },
 *   {
 *     type: 'empty',
 *     departure_date: '2022-01-01 05:00',
 *     departure_airport_id: 3,
 *     arrival_date: '2022-01-01 06:30',
 *     arrival_airport_id: 1,
 *   },
 * ]
 *
 * const scheduleTwo = scheduleOne
 *
 * getAreSchedulesIdentical(scheduleOne, scheduleTwo) // true
 *
 * getAreSchedulesIdentical(scheduleOne, produce(scheduleTwo, draft => {
 *   draft[1].type = 'ferry'
 * })) // false
 */
export function getAreSchedulesIdentical(
  scheduleOne: ScheduleCompareItem[],
  scheduleTwo: ScheduleCompareItem[],
): boolean {
  if (scheduleOne.length !== scheduleTwo.length) {
    return false
  }

  for (let i = 0, j = scheduleOne.length; i < j; i++) {
    const scheduleOneItem = scheduleOne[i]
    const scheduleTwoItem = scheduleTwo[i]

    const isTypeDifferent = scheduleOneItem.type !== scheduleTwoItem.type

    const isDepartureDateDifferent =
      new Date(scheduleOneItem.departure_date).getTime() !==
      new Date(scheduleTwoItem.departure_date).getTime()

    const isArrivalDateDifferent =
      new Date(scheduleOneItem.arrival_date).getTime() !==
      new Date(scheduleTwoItem.arrival_date).getTime()

    const isDepartureAirportDifferent =
      scheduleOneItem.departure_airport_id !==
      scheduleTwoItem.departure_airport_id

    const isArrivalAirportDifferent =
      scheduleOneItem.arrival_airport_id !== scheduleTwoItem.arrival_airport_id

    if (
      isTypeDifferent ||
      isDepartureDateDifferent ||
      isArrivalDateDifferent ||
      isDepartureAirportDifferent ||
      isArrivalAirportDifferent
    ) {
      return false
    }
  }

  return true
}
