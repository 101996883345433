import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from '@tanstack/react-query'
import { api } from '@app/utils/api/api'
import { useMemo } from 'react'
import { AxiosResponse } from 'axios'

export const REFETCH_INTERVAL = 30_000

export const QUERY_KEY = 'unreadMessages'

interface UnreadMessages {
  data: number
  queryKey: unknown[]
  markAllMessagesAsRead: () => Promise<{ success: boolean; message?: string }>
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined,
  ) => Promise<QueryObserverResult<AxiosResponse<number>, unknown>>
}

export default function useUnreadMessages() {
  const operator = useSelector(selectSelectedOperator)

  const { data, refetch } = useQuery(
    [QUERY_KEY, operator?.id],
    () => api.getUnreadMessagesCount(),
    {
      enabled: !!operator?.id,
      refetchInterval: REFETCH_INTERVAL,
    },
  )

  const markAllMessagesAsRead = async () => {
    return await api
      .markMessagesAsRead({})
      .then((res) => res.data)
      .catch((err) => err)
  }

  return useMemo((): UnreadMessages => {
    return {
      data: data?.data || 0,
      refetch,
      queryKey: [QUERY_KEY],
      markAllMessagesAsRead,
    }
  }, [data?.data, markAllMessagesAsRead])
}
