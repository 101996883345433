import { useQuery } from '@tanstack/react-query'

import { api } from '@app/utils/api/api'

export const useSimilarRequestSectionProps = (requestId: number) => {
  const {
    data: axiosResponse,
    isLoading,
    isError,
  } = useQuery(['similar-requests', requestId], () =>
    api.getSimilarRequests(requestId),
  )

  axiosResponse?.data.forEach(
    (curData) => (curData.aircraft_code = curData.aircraft_code ?? 'DELETED'),
  )

  return { isLoading, isError, data: axiosResponse?.data ?? [] }
}
