import Big from 'big.js'
import { identity } from 'ramda'

import { OtherCostUnits } from '@shared/types/offer.types'
import { MINUTES_IN_HOUR } from '@shared/constants'
import { assertUnreachable } from '@shared/utils/assertUnreachable'

import { RoundingMultiples, RoundingTypes } from '@shared/types/aircraft.types'
import { LegDetailDto } from '@shared/dto'

export function getRoundingAmount(
  amount: number,
  roundingType: RoundingTypes,
  roundingMultiple: RoundingMultiples,
): number {
  const roundAmount = getRoundAmount(amount, roundingType, roundingMultiple)

  return Big(roundAmount).sub(amount).toNumber()
}

/**
 * @todo Add docs
 *
 * @todo Refactor
 */
export function getRoundAmount(
  price: number,
  roundingType: RoundingTypes,
  roundingMultiple: RoundingMultiples,
): Big {
  const roundingTypesMap: Record<RoundingTypes, (input: number) => number> = {
    [RoundingTypes.ToNearest]: Math.round,
    [RoundingTypes.Down]: Math.floor,
    [RoundingTypes.Up]: Math.ceil,
    [RoundingTypes.None]: identity,
  }

  const roundingMultiplesMap: Record<RoundingMultiples, number> = {
    [RoundingMultiples.None]: 1,
    [RoundingMultiples.Tenths]: 10,
    [RoundingMultiples.Fifties]: 50,
    [RoundingMultiples.Hundreds]: 100,
    [RoundingMultiples.Thousands]: 1_000,
  }

  const roundingFunction = roundingTypesMap[roundingType]
  const roundingTarget = roundingMultiplesMap[roundingMultiple]

  return Big(roundingFunction(Big(price).div(roundingTarget).toNumber())).times(
    roundingTarget,
  )
}

export function getLegVariableCost<
  T extends Pick<LegDetailDto, 'variable_cost'>,
>(leg: T, rounding: number = 2): number {
  return Big(leg.variable_cost).round(rounding).toNumber()
}

export function getLegFlightTimeProfit<T extends Pick<LegDetailDto, 'profit'>>(
  leg: T,
  rounding: number = 2,
): number {
  return Big(leg.profit).round(rounding).toNumber()
}

export function getLegAirportBudget<
  T extends Pick<LegDetailDto, 'airport_fee' | 'handling_fee'>,
>(leg: T, rounding: number = 2): number {
  const airportFee = getLegAirportFee(leg, rounding)
  const handlingFee = getLegHandlingFee(leg, rounding)

  return Big(airportFee).add(handlingFee).round(rounding).toNumber()
}

export function getLegAirportFee<T extends Pick<LegDetailDto, 'airport_fee'>>(
  leg: T,
  rounding: number = 2,
): number {
  return Big(leg.airport_fee).round(rounding).toNumber()
}

export function getLegHandlingFee<T extends Pick<LegDetailDto, 'handling_fee'>>(
  leg: T,
  rounding: number = 2,
): number {
  return Big(leg.handling_fee).round(rounding).toNumber()
}

export function getLegPassengerFee<
  T extends Pick<
    LegDetailDto,
    'passenger_count' | 'catering_fee' | 'departure_fee' | 'arrival_fee'
  >,
>(leg: T, rounding: number = 2): number {
  const cateringFee = getLegCateringFee(leg, rounding)
  const departureFee = getLegDepartureFee(leg, rounding)
  const arrivalFee = getLegArrivalFee(leg, rounding)

  return Big(cateringFee)
    .add(departureFee)
    .add(arrivalFee)
    .round(rounding)
    .toNumber()
}

export function getLegCateringFee<T extends Pick<LegDetailDto, 'catering_fee'>>(
  leg: T,
  rounding: number = 2,
): number {
  return Big(leg.catering_fee).round(rounding).toNumber()
}

export function getLegDepartureFee<
  T extends Pick<LegDetailDto, 'departure_fee'>,
>(leg: T, rounding: number = 2): number {
  return Big(leg.departure_fee).round(rounding).toNumber()
}

export function getLegArrivalFee<T extends Pick<LegDetailDto, 'arrival_fee'>>(
  leg: T,
  rounding: number = 2,
): number {
  return Big(leg.arrival_fee).round(rounding).toNumber()
}

export function getLegOtherCosts<
  T extends Pick<LegDetailDto, 'other_costs' | 'duration_in_minutes'>,
>(leg: T, rounding: number = 2): number {
  return leg.other_costs
    .reduce((acc, cur) => {
      switch (cur.unit) {
        case OtherCostUnits.Hourly:
          return acc
            .add(cur.value)
            .div(MINUTES_IN_HOUR)
            .times(leg.duration_in_minutes)

        case OtherCostUnits.FlatFee:
          return acc.add(cur.value)

        default:
          assertUnreachable(cur.unit)
      }
    }, Big(0))
    .round(rounding)
    .toNumber()
}
