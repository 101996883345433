import { AVINODE_REQUEST_TRIP_ID_PREFIX } from '@shared/constants'
import { RequestDetailDto, OfferDto } from '@shared/dto'
import { OfferStatuses } from '@shared/enums'

export function getIsAvinodeRequest(
  partialRequest: Pick<RequestDetailDto, 'trip_id'>,
) {
  return partialRequest.trip_id.startsWith(AVINODE_REQUEST_TRIP_ID_PREFIX)
}

export function getTripIdWithoutPrefix(tripId: string) {
  return tripId.split('-')[1]
}

export function getTripIdSource(tripId: string) {
  return tripId.split('-')[0]
}

export const getDisplayOffer = <
  T extends Pick<OfferDto, 'status' | 'updated_at'>,
>(
  offers: T[],
): T => {
  const [lastBookedOffer] = offers
    .filter((offer) => offer.status === OfferStatuses.Booked)
    .sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1))

  if (lastBookedOffer) {
    return lastBookedOffer
  }

  const [lastUpdatedOffer] = offers
    .concat()
    .sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1))

  if (lastUpdatedOffer.status === OfferStatuses.Quoted) {
    return lastUpdatedOffer
  }

  const offerStatusWeights = {
    [OfferStatuses.New]: 0,
    [OfferStatuses.Unhandled]: 1,
    [OfferStatuses.Declined]: 2,
    [OfferStatuses.Draft]: 3,
    [OfferStatuses.Rejected]: 4,
    [OfferStatuses.Quoted]: 5,
    [OfferStatuses.Cancelled]: 6,
    [OfferStatuses.BookedCancelled]: 7,
    [OfferStatuses.Booked]: 8,
  }

  const defaultOfferStatusWeight = -1

  const [displayOffer] = offers.concat().sort((a, b) => {
    if (a.status === b.status) {
      return a.updated_at > b.updated_at ? -1 : 1
    }

    const statusA = offerStatusWeights[a.status] ?? defaultOfferStatusWeight
    const statusB = offerStatusWeights[b.status] ?? defaultOfferStatusWeight

    return statusA > statusB ? -1 : 1
  })

  return displayOffer
}
