import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '@app/components/atoms/Typography/Typography'

import { getLongestRunwayDimensions } from '@shared/utils/airport.utils'

interface AirportTooltipProps {
  airportIcao: string
  airportIata: string | null
  airportCountry: string
  airportRunways: string
  airportCity: string
  timezoneOffsetInMinutes: number
  note?: string
}

const AirportTooltip = ({
  airportIcao,
  airportIata,
  airportCountry,
  airportCity,
  airportRunways,
  timezoneOffsetInMinutes,
  note,
}: AirportTooltipProps) => {
  const { t } = useTranslation()

  const hours = timezoneOffsetInMinutes / 60
  const minutes = timezoneOffsetInMinutes % 60
  const sign = hours < 0 ? '-' : '+'

  const offset =
    sign +
    String(hours).padStart(2, '0') +
    ':' +
    String(minutes).padStart(2, '0')

  const longestRunwayDimensions = getLongestRunwayDimensions(airportRunways)

  return (
    <Container>
      <LabelsContainer>
        <StyledTypography>
          {t('molecules.AirportTooltip.icaoCodeLabel')}
        </StyledTypography>
        <StyledTypography>
          {t('molecules.AirportTooltip.iataCodeLabel')}
        </StyledTypography>
        <StyledTypography>
          {t('molecules.AirportTooltip.cityLabel')}
        </StyledTypography>
        <StyledTypography>
          {t('molecules.AirportTooltip.countryLabel')}
        </StyledTypography>
        <StyledTypography>
          {t('molecules.AirportTooltip.runwayLabel')}
        </StyledTypography>
        <StyledTypography>
          {t('molecules.AirportTooltip.timezoneOffsetLabel')}
        </StyledTypography>
        {note && (
          <StyledTypography>
            {t('molecules.AirportTooltip.note')}
          </StyledTypography>
        )}
      </LabelsContainer>
      <div>
        <StyledTypography>{airportIcao || '-'}</StyledTypography>
        <StyledTypography>{airportIata || '-'}</StyledTypography>
        <StyledTypography>{airportCity || '-'}</StyledTypography>
        <StyledTypography>{airportCountry || '-'}</StyledTypography>

        <StyledTypography>
          {longestRunwayDimensions ??
            t('molecules.AirportTooltip.unknownRunwayDimensions')}
        </StyledTypography>

        <StyledTypography>UTC {offset}</StyledTypography>
        {note && <StyledTypography>{note}</StyledTypography>}
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 0.25rem 0;
`

const LabelsContainer = styled.div`
  width: 8rem;
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.8rem;
  padding: 0 0 0.1rem 0;
  max-width: 11rem;
  white-space: pre-wrap;
`

export default AirportTooltip
